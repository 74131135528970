$fa-font-path: '../../../vendor/fortawesome/font-awesome/webfonts';
@import '../../../vendor/fortawesome/font-awesome/scss/fontawesome';
@import '../../../vendor/fortawesome/font-awesome/scss/solid';

body {
  .select-flag {
    display: inline-block;
    width: 25px;

    img {
      vertical-align: middle;
      width: 20px;
      height: auto;
    }
  }

  .select2-container .select2-search--inline {
    float: none;
    display: inline;
  }

  .select2-container .select2-selection--multiple .select2-selection__rendered {
    overflow: visible;
    white-space: normal;
  }

  .event-review-index [data-action],
  .card-body-countries [data-action],
  .card-body-users [data-action],
  .card-body-tags [data-action] {
    cursor: pointer;
  }

  & > .header {
    .logo {
      font-family: inherit;
    }

    .navbar .nav > li > a > .badge {
      position: absolute;
      top: 4px;
      right: 0;
      font-size: 75%;
    }
  }

  .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .glyphicon,
  .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .fa,
  .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .ion {
    background: none;
    color: inherit;
  }

  .user-panel > .info {
    padding-left: 10px;
  }

  /* add sorting icons to gridview sort links */
  a.asc:after,
  a.desc:after {
    @extend %fa-icon;
    @extend .fas;

    margin-left: 5px;
  }

  a.asc:after,
  .sort-ordinal a.asc:after {
    content: fa-content($fa-var-sort-amount-down);
  }

  a.desc:after,
  .sort-ordinal a.desc:after {
    content: fa-content($fa-var-sort-amount-down-alt);
  }

  .sort-numerical a.asc:after {
    content: fa-content($fa-var-sort-amount-down);
  }

  .sort-numerical a.desc:after {
    content: fa-content($fa-var-sort-amount-down-alt);
  }

  .grid-view th {
    white-space: nowrap;
  }

  .hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
  }

  .error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
  }

  .table .bootstrap-datetimepicker-widget {
    z-index: 9999;
  }

  .no-caret::after {
    display: none;
  }

  .control-sidebar-content {
    .form-group {
      margin-bottom: 0.3rem;

      label,
      .custom-control-label {
        font-weight: normal;
      }
    }

    button {
      margin-top: 1rem;
    }
  }

  .bg-cube {
    width: 60px;
    height: 25px;
    margin: 0 auto;
    border: 1px solid #ccc;
    font-size: 16px;
    line-height: 19px;
    border-radius: 3px;
    display: block;
    text-align: center;
  }

  .content-wrapper {
    overflow-x: hidden;
  }

  .truncate {
    max-width: 150px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .truncate:hover {
    overflow: visible;
    white-space: normal;
    width: auto;
  }
}
